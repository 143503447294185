import React from 'react'
import './Home.css';
import { useNavigate } from "react-router-dom";
import Usps from '../components/Usps';
import Content from '../components/Content';

function Home() {
    const navigate = useNavigate();

    return (
        <section className='home'>
            <div className='box glassmorphism'>
                <div className='container'>
                    <h1 className='text-center'>
                        Pick your favorite <span className='text-pink text-shadow-pink'>skin</span>
                    </h1>
                    <p className='text-center'>Discover premium, high-quality smurf accounts with a lifetime warranty. Customize your experience by selecting your favorite skin to pair with your smurf account.</p>
                    <div className='search-wrapper'>
                        <button onClick={() => navigate("/shop")} className='btn add-to-cart'>Search</button>
                    </div>
                </div>
            </div>
            <Usps />
            <Content />
        </section>
    )
}

export default Home