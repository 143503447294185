import React from "react";
import { useCart } from "../../context/CartContext";
import Toast from "./Toast";

const ToastWrapper = () => {
    const { toastMessage } = useCart(); // Haal de melding uit de context

    return <Toast message={toastMessage} />;
};

export default ToastWrapper;
