import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const initialCart = JSON.parse(localStorage.getItem("cartItems")) || [];
    const [cartItems, setCartItems] = useState(initialCart);
    const [toastMessage, setToastMessage] = useState(null);
    const [discount, setDiscount] = useState({ code: null, value: 0, type: null });

    // Bewaar de winkelwagen in localStorage telkens als deze verandert
    useEffect(() => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        console.log(cartItems);
    }, [cartItems]);

    const addToCart = (product) => {
        const existingItem = cartItems.find((item) => item.id === product.id);

        if (existingItem) {
            setCartItems((prevItems) =>
                prevItems.map((item) =>
                    item.id === product.id
                        ? {
                            ...item,
                            quantity: item.quantity + 1,
                            selectedSkin: product.selectedSkin || item.selectedSkin, // Update skin note indien nodig
                            price: product.selectedSkin ? (item.price || item.sale_price) + 5 : item.price || item.sale_price, // Voeg €5 toe als er een skin is
                        }
                        : item
                )
            );
        } else {
            setCartItems((prevItems) => [
                ...prevItems,
                {
                    ...product,
                    quantity: 1,
                    price: product.selectedSkin ? (product.sale_price || product.price) + 3.50 : product.sale_price || product.price, // Voeg €5 toe als er een skin is
                },
            ]);
        }
        setToastMessage(`${product.name} added to cart!`);
        setTimeout(() => setToastMessage(null), 3000); // Toast verdwijnt na 3 seconden
    };

    const removeFromCart = (id) => {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
    };

    const calculateTotal = () => {
        let total = cartItems.reduce(
            (total, item) => total + item.price * item.quantity,
            0
        );

        // Pas de korting toe als deze actief is
        if (discount.type === "percentage") {
            total -= total * (discount.value / 100);
        } else if (discount.type === "fixed") {
            total -= discount.value;
        }

        // Zorg ervoor dat het totaal niet negatief is
        return total > 0 ? total.toFixed(2) : "0.00";
    };

    const applyDiscount = (code, value, type) => {
        setDiscount({ code, value, type });
        setToastMessage(`Discount code "${code}" applied!`);
        setTimeout(() => setToastMessage(null), 3000);
    };

    return (
        <CartContext.Provider
            value={{
                cartItems,
                addToCart,
                removeFromCart,
                calculateTotal,
                toastMessage,
                applyDiscount,
                discount,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
