import React, { useState } from "react";
import { useCart } from "../context/CartContext";
import "./Cart.css";
import "../components/product-grid/ProductGrid.css";
import { useNavigate } from "react-router-dom";
import { supabase } from '../context/SupabaseClient';

const Cart = () => {
    const { cartItems, removeFromCart, calculateTotal, applyDiscount, discount } = useCart();
    const navigate = useNavigate(); // React Router hook voor navigatie
    const [discountCode, setDiscountCode] = useState("");
    const [error, setError] = useState("");

    const handleApplyDiscount = async () => {
        if (!discountCode) {
            setError("Please enter a discount code.");
            return;
        }

        try {
            const { data, error } = await supabase
                .from("discounts")
                .select("*")
                .eq("code", discountCode)
                .single();

            if (error || !data) {
                setError("Invalid discount code.");
                return;
            }

            const { discount_type, discount_value } = data;
            applyDiscount(discountCode, discount_value, discount_type); // Roep de contextfunctie aan
            setError(""); // Reset eventuele foutmeldingen
        } catch (err) {
            setError("An error occurred. Please try again.");
        }
    };

    console.log(cartItems);

    return (
        <section className="cart">
            <div className="container">
                <h1>Your Cart</h1>
                {cartItems.length === 0 ? (
                    <div className="cart-items glassmorphism" style={{ padding: "20px" }}>
                        <p>Your cart is empty!</p>
                    </div>
                ) : (
                    <>
                        <div className="cart-wrapper">
                            <div className="cart-items">
                                {cartItems.map((item) => (
                                    <div key={item.id} className="cart-item glassmorphism">
                                        <img
                                            src={item.image}
                                            alt={item.name}
                                            className="cart-item-image"
                                        />
                                        <div className="cart-item-details">
                                            <h4>{item.name}</h4>
                                            <p>Region: {item.region}</p>
                                            {item.selectedSkin && (
                                                <p>Skin: {item.selectedSkin} - (+€3.50)</p> // Toon geselecteerde skin
                                            )}
                                            <p>Quantity: {item.quantity}</p>
                                            <p>
                                                Price: €{" "}
                                                {item.sale_price > 0
                                                    ? (item.sale_price * item.quantity).toFixed(2)
                                                    : (item.price * item.quantity).toFixed(2)}
                                            </p>
                                        </div>
                                        <button
                                            className="btn-remove"
                                            onClick={() => removeFromCart(item.id)}
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <div className="discount-box glassmorphism">
                                <h2>Apply any discounts</h2>
                                <input
                                    type="text"
                                    className="glassmorphism"
                                    placeholder="Enter discount code"
                                    value={discountCode}
                                    onChange={(e) => setDiscountCode(e.target.value)}
                                />
                                <button className="btn add-to-cart" onClick={handleApplyDiscount}>
                                    Apply discount
                                </button>
                                {error && <p className="error">{error}</p>}
                                {discount && discount.code && (
                                    <p className="success">Discount "{discount.code}" applied!</p>
                                )}
                            </div>
                            <div className="discount-box glassmorphism">
                                <h2>Note:</h2>
                                <p>
                                    After purchase, create a discord ticket with the order number. Or
                                    send an Administrator a DM.
                                </p>
                                <a style={{ textDecoration: 'underline' }} href="https://discord.gg/77xCEXZa4U">Join our discord now!</a>
                            </div>
                            <div className="discount-box glassmorphism">
                                <h2>Checkout</h2>
                                <span className="total">Total:</span>{" "}
                                <span className="total-price">€ {calculateTotal()}</span>
                                <div className="divider green"></div>
                                <button
                                    onClick={() => navigate("/checkout")}
                                    className="btn add-to-cart"
                                >
                                    Proceed to Checkout
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default Cart;
