import React from 'react';
import "./SkeletonCard.css"; // Zelfde styling gebruiken

function SkeletonCard() {
    return (
        <div className="product-card skeleton-card">
            <div className="product-card_image skeleton-image"></div>
            <div className="product-content">
                <div className="skeleton-text skeleton-title"></div>
                <div className="skeleton-text skeleton-badges"></div>
                <div className="skeleton-text skeleton-price"></div>
                <div className="product-actions">
                    <div className="skeleton-text skeleton-button"></div>
                </div>
            </div>
        </div>
    );
}

export default SkeletonCard;
