import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../context/SupabaseClient";
import { useAuth } from "../context/AuthContext";
import "./Login.css";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { user, loading } = useAuth(); // Haal de user en loading state uit de context
    const navigate = useNavigate();

    useEffect(() => {
        // Redirect direct als er al een sessie (user) bestaat
        if (!loading && user) {
            navigate('/account', { replace: true });
        }
    }, [user, loading, navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();

        const { error } = await supabase.auth.signInWithPassword({
            email,
            password,
        });

        if (error) {
            setError(error.message);
        }
    };

    return (
        <section className='login'>
            <div className="container">
                <div className="login-wrapper">
                    <div className='login-box glassmorphism'>
                        <div className='login-content'>
                            <h1>Login</h1>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </div>
                        <div className='login-form'>
                            <form onSubmit={handleLogin}>
                                <input
                                    type="email"
                                    placeholder="E-mailadres"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    type="password"
                                    placeholder="Wachtwoord"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button type="submit" className='btn add-to-cart'>Login</button>
                            </form>
                        </div>
                        <div className="login-box_footer">
                            <p>Don't have an account yet? <a href="/register">Register here!</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
