import React from 'react';
import "./Navigation.css";
import { useCart } from "../../context/CartContext";
import { useAuth } from "../../context/AuthContext";
import { supabase } from '../../context/SupabaseClient';

function Navigation() {
    const { cartItems } = useCart();
    const { user } = useAuth(); // Haal de gebruiker uit de AuthContext
    const handleLogout = async () => {
        await supabase.auth.signOut();
        window.location.href = '/login';
    };
    return (
        <div>
            <div class="top-bar">
                <div class="marquee">
                    <div class="marquee-content">
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                    </div>
                    <div class="marquee-content">
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                        <p>🎄 Christmas10 for 10% OFF 🎄</p>
                    </div>
                </div>
            </div>


            <nav className="navbar glassmorphism">
                <div className="container">
                    <div className="navbar-left">
                        <ul className="nav-links">
                            <li><a href="/">Home</a></li>
                            <li><a href="/shop">Shop</a></li>
                            <li><a target='_blank' href="https://discord.gg/77xCEXZa4U">Discord</a></li>
                        </ul>
                    </div>
                    <div className="logo-wrapper">
                        <a href="/" className="logo">
                            <img src="gigasmurfs-logo.png" alt="Gigasmurfs logo" width="auto" height="auto" />
                        </a>
                    </div>
                    <div className="navbar-right">
                        <a href="/cart" className="nav-icon">
                            Cart<span className="icon">🛍️</span>
                            <span className="icon-bubble">{cartItems.length}</span>
                        </a>
                        {/* Conditional rendering for My Account */}
                        <a href={user ? "/account" : "/login"} className="nav-icon">
                            {user ? 'My Account 😀' : 'Login'}
                        </a>
                        {user ? <button className='btn-primary' onClick={handleLogout}>Logout</button> : ''}
                    </div>
                </div>
            </nav>
        </div >
    );
}

export default Navigation;
