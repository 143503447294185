import React, { useState } from 'react';
import { supabase } from '../context/SupabaseClient';
import "./Login.css";

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleRegister = async (e) => {
        e.preventDefault();

        const { error } = await supabase.auth.signUp({
            email,
            password,
        });

        if (error) {
            setError(error.message);
        } else {
            setMessage('Registration successful! Check your email to confirm your account.');
        }
    };

    return (
        <section className='login'>
            <div className="container">
                <div className="login-wrapper">
                    <div className='login-box glassmorphism'>
                        <div className='login-content'>
                            <h1>Register</h1>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            {message && <p style={{ color: 'green' }}>{message}</p>}
                        </div>
                        <div className='login-form'>
                            <form onSubmit={handleRegister}>
                                <input
                                    type="email"
                                    placeholder="E-mailadres"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <input
                                    type="password"
                                    placeholder="Wachtwoord"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button type="submit" className='btn add-to-cart'>Register</button>
                            </form>
                        </div>
                        <div className="login-box_footer">
                            <p>Already have an account? <a href="/login">Login here!</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Register;
