import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CartProvider } from "./context/CartContext";

import Navigation from "./components/global/Navigation";
import ProductGrid from "./components/product-grid/ProductGrid";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import OrderConfirmation from "./pages/OrderConfirmation";
import ToastWrapper from "./components/global/ToastWrapper";
import ProtectedRoute from "./context/ProtectedRoute";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Account from "./pages/Account";
import { AuthProvider } from "./context/AuthContext";
import Home from "./pages/Home";

const App = () => {
  return (
    <AuthProvider>
      <CartProvider>
        <ToastWrapper />
        <Router>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shop" element={<ProductGrid />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            } />
            <Route path="/order-confirmation" element={
              <ProtectedRoute>
                <OrderConfirmation />
              </ProtectedRoute>
            } />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </CartProvider>
    </AuthProvider>
  );
};

export default App;
