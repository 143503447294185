import React from "react";
import "./Toast.css"; // Styling voor de toast

const Toast = ({ message, onClose }) => {
    if (!message) return null;
    return (
        <div className="toast glassmorphism">
            <p>{message}</p>
        </div>
    );
};


export default Toast;
