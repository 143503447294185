import React, { useState, useEffect } from "react";
import { useCart } from "../context/CartContext";
import { Elements, useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import "./Checkout.css";
import { supabase } from '../context/SupabaseClient';

// Stripe public key
const stripePromise = loadStripe("pk_live_51KS3AWJfdczsQfXfXr5gRVxl7bJDCCGFKKYGgqMqtaNUTlbXeGZi4g7GBQ4aq7scO3WIzgFn0WmzqhMISnfxKRXu00bbyw6n9z");

const Checkout = () => {
    const { calculateTotal } = useCart();
    const [clientSecret, setClientSecret] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    // Haal clientSecret op bij laden van component
    useEffect(() => {
        const fetchClientSecret = async () => {
            const totalAmount = calculateTotal() * 100; // Converteer naar centen
            const { data: { user }, error } = await supabase.auth.getUser();

            if (error || !user) {
                setErrorMessage("You must be logged in to proceed.");
                console.error("User not logged in or error fetching user:", error);
                window.location.href = '/login'; // Redirect als er geen sessie is
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/create-payment-intent`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ amount: totalAmount, userId: user.id }),
                });

                console.log("Verstuurde payload:", { amount: totalAmount, userId: user.id });

                if (!response.ok) {
                    const errorData = await response.text();
                    console.error("Response error:", errorData);
                    throw new Error("Failed to create payment intent");
                }

                const data = await response.json();
                setClientSecret(data.clientSecret);
            } catch (error) {
                setErrorMessage("Unable to fetch payment details. Please try again.");
                console.error("Error fetching clientSecret:", error);
            }
        };

        fetchClientSecret();
    }, [calculateTotal]);

    if (errorMessage) {
        return <div className="error-message">{errorMessage}</div>;
    }

    if (!clientSecret) {
        return <div>Loading payment information...</div>;
    }

    const elementsOptions = {
        clientSecret,
        appearance: {
            theme: "night",
            variables: {
                fontFamily: "'Montserrat', sans-serif",
                fontSizeBase: "16px",
                colorPrimary: "#3a7afe",
                colorText: "#fff",
                colorTextSecondary: "#fff",
                colorTextPlaceholder: "#ccc",
            },
            rules: {
                ".Tab": {
                    backgroundColor: "rgba(42, 8, 69, 0.507)",
                    color: "#fff",
                    borderRadius: "10px",
                },
                ".Tab:hover": {
                    backgroundColor: "#FF006E",
                },
                ".Tab--selected": {
                    backgroundColor: "#FF006E",
                    color: "#fff",
                },
                ".Input": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    padding: "10px",
                    color: "#fff",
                },
            },
        },
    };

    return (
        <Elements stripe={stripePromise} options={elementsOptions}>
            <CheckoutForm clientSecret={clientSecret} navigate={navigate} />
        </Elements>
    );
};

const CheckoutForm = ({ clientSecret, navigate }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { calculateTotal } = useCart();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            alert("Stripe is not loaded");
            return;
        }

        try {
            const { error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `https://gigasmurfs.com/order-confirmation`, // Simpele URL
                },
            });

            if (error) {
                console.error("Payment error:", error);
                alert("Payment failed: " + error.message);
                return;
            }

            if (paymentIntent?.status === "succeeded") {
                console.log("Payment succeeded:", paymentIntent);
                navigate("/order-confirmation"); // Redirect naar bevestigingspagina
            }
        } catch (error) {
            console.error("Error during payment submission:", error);
        }
    };

    return (
        <section className="checkout-page">
            <div className="container">
                <h1>Checkout</h1>
                <div className="checkout-box glassmorphism">
                    <form onSubmit={handleSubmit}>
                        <div className="payment-methods">
                            {/* Andere methoden tonen die beschikbaar zijn */}
                            <PaymentElement />
                        </div>
                        <button type="submit" className="btn add-to-cart" disabled={!stripe}>
                            Pay Now (€{calculateTotal()})
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Checkout;
