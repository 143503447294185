import React, { useEffect, useState } from 'react';
import "./ProductGrid.css";
import ProductCard from './ProductCard';
import SkeletonCard from './SkeletonCard';
import { supabase } from '../../context/SupabaseClient';

function ProductGrid() {
    const [skins, setSkins] = useState([]); // Huidige skins voor de pagina
    const [filteredSkins, setFilteredSkins] = useState([]); // Gefilterde skins voor zoekopdracht
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [selectedSkin, setSelectedSkin] = useState(null); // Geselecteerde skin
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [regions, setRegions] = useState([]);
    const [blueEssenceOptions, setBlueEssenceOptions] = useState([]);
    const [accountTypeOptions, setAccountTypeOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [regionFilter, setRegionFilter] = useState('');
    const [priceRange, setPriceRange] = useState([0, 100]);
    const [blueEssenceFilter, setBlueEssenceFilter] = useState('');
    const [accountTypeFilter, setAccountTypeFilter] = useState('');
    const [showSkins, setShowSkins] = useState(false); // Nieuw: Toggle state
    const [searchTerm, setSearchTerm] = useState(''); // Zoekterm voor skins

    const accountTypeLabels = {
        "ranked-ready": "Ranked Ready",
        "level30": "Level 30",
    };

    const skinsPerPage = 18; // Aantal skins per pagina

    const fetchSkins = async (page, searchTerm = '') => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/skins?page=${page}&limit=${skinsPerPage}&search=${searchTerm}`);
            const data = await response.json();
            setSkins(data.skins);
            setFilteredSkins(data.skins); // Update gefilterde skins
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Fout bij het ophalen van skins:', error);
        }
        setLoading(false);
    };

    // Bijwerken van zoekfunctionaliteit
    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        fetchSkins(1, term); // Herlaad vanaf pagina 1 met de zoekterm
    };

    useEffect(() => {
        fetchSkins(currentPage);
    }, [currentPage]);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const formatSkinName = (skinFileName) => {
        return skinFileName
            .replace(/_/g, ' ') // Vervang underscores met spaties
            .replace(/\bskin\.jpg\b/i, '') // Verwijder "skin.jpg" uit de naam
            .trim();
    };

    const handleSelectSkin = (skin) => {
        setSelectedSkin(selectedSkin === skin ? null : skin); // Toggle selectie
    };

    const filterProducts = () => {
        let filtered = products;

        // Pas filters toe
        if (regionFilter) {
            filtered = filtered.filter((product) => product.region === regionFilter);
        }

        if (blueEssenceFilter) {
            filtered = filtered.filter((product) => product.blueessence === blueEssenceFilter);
        }

        // Filter op prijsbereik
        filtered = filtered.filter((product) => {
            const price = product.sale_price || product.price;
            return price >= priceRange[0] && price <= priceRange[1];
        });

        if (accountTypeFilter) {
            filtered = filtered.filter((product) => product.accountType === accountTypeFilter);
        }

        setFilteredProducts(filtered);
    };

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            const { data, error } = await supabase.from('products').select('*');

            if (error) {
                console.error('Error fetching products:', error.message);
                setError('Failed to load products');
            } else {
                const sortedData = data.sort((a, b) => {
                    const priceA = a.sale_price || a.price;
                    const priceB = b.sale_price || b.price;
                    return priceA - priceB;
                });
                setProducts(sortedData);
                setFilteredProducts(sortedData);

                const uniqueRegions = [...new Set(data.map((product) => product.region))];
                setRegions(uniqueRegions);

                const uniqueBlueEssence = [...new Set(data.map((product) => product.blueessence))];
                setBlueEssenceOptions(uniqueBlueEssence);

                const uniqueAccountTypes = [...new Set(data.map((product) => product.accountType))];
                setAccountTypeOptions(uniqueAccountTypes);
            }
            setLoading(false);
        };

        fetchProducts();
    }, []);

    useEffect(() => {
        filterProducts();
    }, [regionFilter, blueEssenceFilter, priceRange, accountTypeFilter, products]);

    return (
        <section className="product-grid-section">
            <div className="container">
                <div className="skins-selection">
                    <h3>Select Skin (+€3.50)</h3>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search for a skin..."
                            value={searchTerm}
                            onChange={handleSearch}
                            onClick={() => setShowSkins(!showSkins)}

                            className="glassmorphism"
                        />
                    </div>
                    <button
                        onClick={() => setShowSkins(!showSkins)}
                        className="btn add-to-cart glassmorphism"
                    >
                        {showSkins ? "Hide Skins" : "Show Skins"}
                    </button>
                    {showSkins && (
                        <>
                            <div className="skins-grid">
                                {loading
                                    ? Array.from({ length: skinsPerPage }).map((_, index) => (
                                        <SkeletonCard key={index} />
                                    ))
                                    : filteredSkins.map((skin, index) => (
                                        <div
                                            key={index}
                                            className={`skin-item ${selectedSkin === skin ? 'selected' : ''}`}
                                            onClick={() => handleSelectSkin(skin)}
                                        >
                                            <img
                                                src={`/skins_thumbnails/${skin}`}
                                                alt={formatSkinName(skin)}
                                            />
                                            <p className="skin-name">{formatSkinName(skin)}</p>
                                        </div>
                                    ))}
                            </div>
                            <div className="pagination">
                                <button className="btn add-to-cart glassmorphism"
                                    onClick={handlePrevPage} disabled={currentPage === 1}>
                                    Previous
                                </button>
                                <span>
                                    Page {currentPage} of {totalPages}
                                </span>
                                <button className="btn add-to-cart glassmorphism"
                                    onClick={handleNextPage}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                        </>
                    )}
                </div>

                {selectedSkin && (
                    <div className="selected-skin-summary">
                        <p>
                            Selected Skin: <strong>{formatSkinName(selectedSkin)}</strong>
                        </p>
                    </div>
                )}

                <div className='product-page'>
                    <div className="filter-menu glassmorphism">
                        <div className="filter-group ">
                            <h4>Region</h4>
                            <select
                                value={regionFilter}
                                onChange={(e) => setRegionFilter(e.target.value)}
                                className='glassmorphism'
                            >
                                <option value="">All Regions</option>
                                {regions.map((region) => (
                                    <option key={region} value={region}>
                                        {region}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="filter-group">
                            <h4>Account type</h4>
                            {accountTypeOptions.map((option) => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        name="accounttype"
                                        value={option}
                                        checked={accountTypeFilter === option}
                                        onChange={(e) => setAccountTypeFilter(e.target.value)}
                                    />
                                    {accountTypeLabels[option] || option}
                                </label>
                            ))}
                            <label>
                                <input
                                    type="radio"
                                    name="accounttype"
                                    value=""
                                    checked={accountTypeFilter === ''}
                                    onChange={() => setAccountTypeFilter('')}
                                />
                                All
                            </label>
                        </div>

                        <div className="filter-group">
                            <h4>Price Range (€)</h4>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                step="5"
                                value={priceRange[1]}
                                onChange={(e) =>
                                    setPriceRange([priceRange[0], Number(e.target.value)])
                                }
                            />
                            <p>
                                €{priceRange[0]} - €{priceRange[1] || 'Max'}
                            </p>
                        </div>

                        <div className="filter-group">
                            <h4>Blue Essence</h4>
                            {blueEssenceOptions.map((option) => (
                                <label key={option}>
                                    <input
                                        type="radio"
                                        name="blueessence"
                                        value={option}
                                        checked={blueEssenceFilter === option}
                                        onChange={(e) => setBlueEssenceFilter(e.target.value)}
                                    />
                                    {option}
                                </label>
                            ))}
                            <label>
                                <input
                                    type="radio"
                                    name="blueessence"
                                    value=""
                                    checked={blueEssenceFilter === ''}
                                    onChange={() => setBlueEssenceFilter('')}
                                />
                                All
                            </label>
                        </div>
                    </div>

                    <div className="product-grid">
                        {loading
                            ? Array.from({ length: 6 }).map((_, index) => (
                                <SkeletonCard key={index} />
                            ))
                            : filteredProducts.map((product) => (
                                <ProductCard key={product.id} {...product} selectedSkin={selectedSkin ? formatSkinName(selectedSkin) : null} />
                            ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductGrid;
