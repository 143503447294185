import React from 'react'

function Content() {
    return (
        <div className='py-16'>
            <div className='container'>
                <h2>Buy Unranked LoL Smurf Accounts</h2>
                <h3>The Only Site You'll Ever Need!</h3>
                <p>
                    Start fresh with our unranked League of Legends smurf accounts! Whether you want to explore new strategies, team up with friends, or begin a brand-new journey, our accounts are the perfect choice. Begin at unranked and enjoy the thrill of climbing the ranks your way.

                    We offer a wide selection of accounts to suit your preferences, including both basic and hand-leveled options. Our accounts are secure, with minimal risk of bans or issues, so you can play with peace of mind. Receive your account details instantly after purchase and jump into the action right away.

                    Need assistance? Our support team is available to help you anytime!
                </p>
            </div>
        </div>
    )
}

export default Content