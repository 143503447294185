import React from "react";
import "./ProductGrid.css";
import { useCart } from "../../context/CartContext";

const ProductCard = ({
    id,
    image,
    region,
    level,
    blueessence,
    price,
    sale_price,
    badges = [],
    selectedSkin, // Voeg deze prop toe
}) => {
    const { addToCart } = useCart();

    return (
        <div
            className={`product-card glassmorphism ${sale_price > 0 ? "on-sale" : ""}`}
        >
            <div className="product-card_image">
                <img src={image} alt="Product" className="product-image" />
            </div>
            <div className="product-content">
                <div className="product-badges">
                    {sale_price > 0 ? (
                        <>
                            <span className="badge safe">SALE</span>
                        </>
                    ) : (
                        <span className="visibility-hidden">hidden</span>
                    )}
                    {badges?.map((badge, index) => (
                        <span key={index} className={`badge ${badge.type}`}>
                            {badge.label}
                        </span>
                    ))}
                </div>
                <h3 className="product-title">Region: {region}</h3>
                <p>Level: {level}</p>
                <p>Blue Essence: {blueessence}</p>
                <div className="price-wrapper">
                    {sale_price > 0 ? (
                        <>
                            <p className="product-price sale-price">€{sale_price.toFixed(2)}</p>
                            <p className="original-price">€ {price.toFixed(2)}</p>
                        </>
                    ) : (
                        <p className="product-price">€ {price.toFixed(2)}</p>
                    )}
                </div>
                {/* Toon geselecteerde skin als er een is */}
                {selectedSkin && (
                    <div className="selected-skin-note">
                        <p><strong>Selected Skin:</strong> {selectedSkin}</p>
                    </div>
                )}
                <div className="product-actions">
                    <button className="btn add-to-cart" onClick={() =>
                        addToCart({
                            id,
                            name: `Smurf - ${blueessence} - ${region}`,
                            image,
                            region,
                            price,
                            sale_price,
                            selectedSkin: selectedSkin,
                        })
                    }>Add to cart</button>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
