import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./OrderConfirmation.css";

const OrderConfirmation = () => {
    const [payment, setPayment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const paymentIntentId = queryParams.get("payment_intent");

        if (!paymentIntentId) {
            setError("No payment information available.");
            setLoading(false);
            return;
        }

        const fetchPayment = async () => {
            try {
                // Roep de Supabase endpoint aan om de betaling op te halen
                const response = await fetch(`${process.env.REACT_APP_API_URL}/payment-details`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ paymentIntentId }),
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch payment details.");
                }

                const data = await response.json();
                setPayment(data.payment); // Sla de betalingsgegevens op in de state
            } catch (err) {
                console.error("Error fetching payment details:", err.message);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPayment();
    }, [location.search]);

    if (loading) return <div>Validating payment...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <section className="order-confirmation">
            <div className="container">
                <div className="confirm-box glassmorphism">
                    <h1>Thank you for your purchase!</h1>
                    {payment ? (
                        <>
                            <p><strong>Status:</strong> {payment.status === "succeeded" ? "Payment received - Waiting for account." : "Payment pending."}</p>
                            <p><strong>Order ID:</strong> {payment.payment_intent_id}</p>
                            <p><strong>Amount:</strong> €{(payment.amount / 100).toFixed(2)}</p>
                            <p><strong>Date:</strong> {new Date(payment.created_at).toLocaleString()}</p>
                        </>
                    ) : (
                        <p>No payment details available.</p>
                    )}
                </div>
            </div>
        </section>
    );
};

export default OrderConfirmation;
