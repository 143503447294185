import React from 'react'
import './Usps.css';
function Usps() {
    return (
        <div className='usp-section py-16'>
            <div className='container'>
                <div className='box-wrapper'>
                    <div className='box usp-box glassmorphism'>
                        <div className='two-cols'>
                            <div className='left'>
                                <p>Excellent</p>
                                <img className='stars' src='/stars-5.svg' width={125} />
                                <p>4.9 / 5 rating</p>
                            </div>
                            <div className='right'>
                                <img src='/trustpilot.svg' width={125} />
                            </div>
                        </div>
                    </div>
                    <div className='box usp-box glassmorphism'>
                        <div className='two-cols'>
                            <div className='left'>
                                <p>Lifetime Warranty on <span className='text-pink text-shadow-pink'>Ranked Ready</span> accounts</p>
                            </div>
                            <div className='right'>
                                <img src='/veilig.png' width={125} />
                            </div>
                        </div>
                    </div>
                    <div className='box usp-box glassmorphism'>
                        <div className='two-cols'>
                            <div className='left'>
                                <p>We offer alot of payment methods via <span className='text-pink text-shadow-pink'>Stripe</span></p>
                            </div>
                            <div className='right'>
                                <img src='/afrekenen.png' width={125} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Usps