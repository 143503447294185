import React, { useState, useEffect } from 'react';
import { supabase } from '../context/SupabaseClient';
import './Account.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";


const Account = () => {
    const [user, setUser] = useState(null);
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isRevealed, setIsRevealed] = useState(false);


    const toggleReveal = () => {
        setIsRevealed(!isRevealed);
    };

    useEffect(() => {
        const getUserAndPayments = async () => {
            const { data: userData, error: userError } = await supabase.auth.getUser();

            if (userError) {
                console.error("Error fetching user:", userError);
                setLoading(false);
                return;
            }

            setUser(userData.user);

            // Fetch payments for the logged-in user
            const { data: paymentsData, error: paymentsError } = await supabase
                .from("payments")
                .select("*")
                .eq("user_id", userData.user.id);

            if (paymentsError) {
                console.error("Error fetching payments:", paymentsError);
            } else {
                setPayments(paymentsData);
            }

            setLoading(false);
        };

        getUserAndPayments();
    }, []);

    if (loading) {
        return (
            <div className="loading-container">
                <p>Loading...</p>
            </div>
        );
    }

    return (
        <section className="account-container">
            <div class="container">
                {user ? (
                    <div className="account-content">
                        <h3>Your Payments</h3>
                        {payments.length > 0 ? (
                            <div className="payments-table-container">
                                <table className="payments-table glassmorphism">
                                    <thead>
                                        <tr>
                                            <th>Payment ID</th>
                                            <th>Amount</th>
                                            <th>Currency</th>
                                            <th>Status</th>
                                            <th>Date</th>
                                            <th>Username</th>
                                            <th>Password</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {payments.map((payment) => (
                                            <tr key={payment.id}>
                                                <td>{payment.payment_intent_id}</td>
                                                <td>€{(payment.amount / 100).toFixed(2)}</td>
                                                <td>{payment.currency.toUpperCase()}</td>
                                                <td>{payment.status}</td>
                                                <td>{new Date(payment.created_at).toLocaleString()}</td>
                                                <td>{payment.username}</td>
                                                <td className="password-reveal" style={{ display: "flex", alignItems: "center" }}>
                                                    <span
                                                        style={{
                                                            filter: isRevealed ? "none" : "blur(8px)",
                                                            marginRight: "10px",
                                                            transition: "filter 0.3s ease",
                                                        }}
                                                    >
                                                        {payment.password}
                                                    </span>
                                                    <span
                                                        onClick={toggleReveal}
                                                        style={{ cursor: "pointer", position: "relative" }}
                                                    >
                                                        {isRevealed ? <FaEyeSlash /> : <FaEye />}
                                                        <span className="tooltip">
                                                            {isRevealed ? "Hide password" : "Show password"}
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>No payments found.</p>
                        )}
                    </div>
                ) : (
                    <p>User not logged in.</p>
                )}
            </div>
        </section>
    );
};

export default Account;
